import {
    Container,
    Row
} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import logo from './logo.svg';
import './App.css';

function App() {
    return (
        <div
            className="App d-flex align-items-center"
            style={{
                height: '100vh',
                backgroundColor: 'black',
                color: 'white',
            }}
            >
            <Container>
                <Row>
                    <div>
                        <img
                            src="cheeseclub.jpg"
                            onClick={() => {
                                window.open('https://discord.gg/wVABks3fYC', '_blank');
                            }}
                        />
                    </div>
                </Row>

                <Row>
                    <span>
                        Cheese Club
                    </span>
                </Row>
            </Container>
        </div>
    );
}

export default App;
